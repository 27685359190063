// http://getbootstrap.com/customize/#less-variables

//== Colors
$brand-primary: #490e00;

//== Scaffolding
$body-bg: $brand-primary;
$text-color: $brand-primary;

//== Typography
$font-family-sans-serif: 'Roboto Condensed', sans-serif;
$font-size-base: $em-base;

$font-size-h1: ($em-base + 6);
$font-size-h2: ($em-base + 4);

//== Iconography
$icon-font-path: "../fonts/";

//== Components
$border-radius-base: 0;

//== Grid system
$grid-float-breakpoint: 300px;

//== Container sizes
$container-desktop: 800px;
$container-large-desktop: $container-desktop;

//== Navbar
// Basics of a navbar
$navbar-margin-bottom: 0;

$navbar-default-color: #fff;
$navbar-default-bg: $brand-primary;
$navbar-default-border: $navbar-default-bg;

// Navbar links
$navbar-default-link-color: $navbar-default-color;
$navbar-default-link-hover-color: darken($navbar-default-link-color, 20%);

//== Type
$hr-border: $brand-primary;
