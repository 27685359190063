// IE 10+, FF 47+, Chrome 49+

@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";

@import "variables-bourbon";
@import "../../node_modules/bourbon/core/bourbon";
@import "variables-bootstrap";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

a {
  text-decoration: underline;

  &.external {
    background: transparent url('../img/icon-external-link.png') center left no-repeat;
    padding-left: 15px;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: $line-height-computed / 2;
  margin-top: 0;
}

ol, ul {
  padding-left: 15px;
}

/** Layout. **/
div.body-content {
  background-color: #fff;
  padding-top: $line-height-computed;
}

footer {
  background-color: $navbar-default-bg;
  height: $navbar-height;

  .menu {
    padding-top: $line-height-computed / 2;

    a {
      color: #fff;
    }
  }
}

header {
  background: #8a2003 url('../img/banner.png') top center no-repeat;
  padding: $line-height-computed 0;

  h1 {
    color: #fff;
    font-size: ($em-base + 16);
    

    img {
      width: 70px;
    }

    .subtitle {
      display: block;
      margin-top: 10px;
    }
  }
}

nav {
  a {
    text-decoration: none;
  }
}

section.messages {
  font-size: ($em-base + 2);
  margin-bottom: $line-height-computed;
  margin-top: -$line-height-computed;

  p {
    margin: 0;
    padding: $line-height-computed 0;
  }
}

section#about {
  .gallery {
    .card {
      img {
        display: block;
        margin-top: 5px;
      }
    }
  }
}

section#announcement {
  background-color: $state-warning-bg;
  color: $state-warning-text;
}

section#enquiries {
  background-color: $gray-lighter;
  padding-bottom: $line-height-computed;
  padding-top: $line-height-computed;

  .actions {
    .helper-text {
      display: inline-block;
      margin-left: 5px;

      @media (max-width: $screen-xs-max) {
        display: block;
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }
}

section#locations {
  margin-bottom: $line-height-computed;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 0;
  }

  .card {
    position: relative;
    z-index: 1;

    &.faai-di::before {
      background-image: url('../img/logo-faai-di-small.png');
    }

    &.ka-soh::before {
      background-image: url('../img/logo-ka-soh-small.png');
    }

    &.swee-kee::before {
      background-image: url('../img/logo-swee-kee-small.png');
    }
  }

  .card::before {
    background: top right no-repeat;
    background-size: auto 50px;
    content: "";
    height: 100%;
    position: absolute;
    opacity: .5;
    right: 10px;
    top: 10px; 
    width: 100%;
    z-index: -1;
  }
}
/** **/

.card {
  background-color: #f8f8f8;
  border: 1px solid #eee;
  border-radius: $border-radius-small;
  margin-bottom: 10px;
  padding: 10px 10px 0;

  hr {
    border-color: #eee;
    margin: 10px 0;
  }

  ul.list {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 5px;
    }
  }

  .message {
    padding: 10px;
  }
}

.spinning {
  @include animation(spinning 1.5s infinite linear);
}

.bottom {
  margin-bottom: 0;
}

/** Animations. **/
@keyframes spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/** **/
